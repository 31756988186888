
  import { computed, defineComponent, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { hideModal } from '@/core/helpers/dom';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n/index';
  import { QualityGroupObject } from '@/store/modules/IVE/QualityGroupsModule';

  export default defineComponent({
    name: 'group-weight-editing',
    props: {
      editData: { type: Object, required: false },
    },
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    emit: ['new-value-created'],
    async setup(props, { emit }) {
      const submitButtonRef = ref<null | HTMLButtonElement>(null);
      const editWeightValueModalRef = ref<null | HTMLElement>(null);
      const store = useStore();
      const { t, te } = useI18n();

      const { data } = await store.dispatch(Actions.GET_QUALITY_GROUP);
      const optionsValues = ref<Array<QualityGroupObject>>(data.data);

      let dynamicSchema = ref({});
      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };
      dynamicSchema.value = {};
      optionsValues.value.forEach((v) => {
        dynamicSchema.value[`field_${v.id}`] = Yup.number()
          .typeError('Please enter a valid number')
          .required('Weighed % is required')
          .min(0, 'Minimum at least 0')
          .label(v.title)
          .test('max', 'Total should equal 100%', function () {
            let sum = 0;
            optionsValues.value.forEach((v) => {
              sum = sum + Number(v.weight);
            });
            return sum == 100;
          });
      });
      const validationSchema = computed(() => {
        return Yup.object(dynamicSchema.value);
      });

      const submit = async (values) => {
        if (submitButtonRef.value) {
          // eslint-disable-next-line
          submitButtonRef.value!.disabled = true;
          submitButtonRef.value.setAttribute('data-kt-indicator', 'on');
        }

        const data = Object.keys(values).map((v) => ({
          id: Number(v.replace('field_', '')),
          weight: Number(values[v]),
        }));

        await store.dispatch(Actions.UPDATE_GROUPS_WEIGHT, {
          id: '',
          data: { data },
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButtonRef.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButtonRef.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_GROUPS_WEIGHT'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            emit('new-value-created');
            //Deactivate indicator
            submitButtonRef.value?.removeAttribute('data-kt-indicator');
            // eslint-disable-next-line
            submitButtonRef.value!.disabled = false;
            hideModal(editWeightValueModalRef.value);
          });
        }
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        optionsValues,
        translate,
        validationSchema,
        submit,
        submitButtonRef,
        editWeightValueModalRef,
      };
    },
  });
