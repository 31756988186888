
  import { defineComponent, ref, watch } from 'vue';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useI18n } from 'vue-i18n/index';
  import { PermissionObject } from '@/store/modules/Members/PermissionsModule';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import { useRouter } from 'vue-router';

  export default defineComponent({
    name: 'group-values-table',
    components: {
      Datatable,
    },
    emit: [
      'show-create-question-modal',
      'show-edit-modal',
      'show-edit-question-modal',
      'show-edit-question-weight-modal',
    ],
    props: {
      value: { type: Object, required: true },
    },
    async setup(props, { emit }) {
      const store = useStore();
      const tableData = ref(props.value.iveFqQuestions);
      const tableTitle = ref(props.value.title);
      const weight = ref(props.value.weight);
      const groupId = ref(props.value.id);
      const router = useRouter();
      const { t, te } = useI18n();
      const perms = ref<Array<PermissionObject>>([]);
      const { can } = useAbility();

      watch(
        () => props.value,
        (changed) => {
          tableData.value = changed.iveFqQuestions;
        }
      );

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };
      const loading = ref(false);
      const tableHeader = ref([
        {
          name: translate('idColumn'),
          key: 'id',
          sortable: true,
        },
        {
          name: translate('title'),
          key: 'title',
          sortable: true,
        },
        {
          name: translate('weight'),
          key: 'weight',
          sortable: true,
        },
        {
          name: translate('status'),
          key: 'isActive',
          sortable: false,
        },
        {
          name: translate('actions'),
          key: 'actions',
          sortable: false,
        },
      ]);

      const toggleStatusOption = async (option) => {
        const deletedMessage = option.isActive
          ? 'confirmInActive'
          : 'confirmActive';
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          text: translate(deletedMessage),
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });
        if (result.value) {
          loading.value = true;
          await store.dispatch(Actions.DELETE_QUESTION, option.id);
          await store.dispatch(Actions.GET_QUALITY_GROUPS);
          loading.value = false;
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          } else {
            const successMsg = option.isActive
              ? 'SUCCESSFULLY_INACTIVATED'
              : 'SUCCESSFULLY_ACTIVEATED';
            Swal.fire({
              text: translate(successMsg),
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: translate('ok'),
              customClass: {
                confirmButton: 'btn btn-light-primary',
              },
            });
          }
        }
      };

      const editOption = (id) => {
        emit('show-edit-question-modal', id);
      };

      const ShowEditWeightModal = () => {
        emit('show-edit-question-weight-modal', {
          value: props.value,
          groupTitle: tableTitle.value,
        });
      };

      const showCreateQuestionModal = () => {
        emit('show-create-question-modal', {
          groupId: groupId.value,
        });
      };
      const showEditGroupModal = () => {
        emit('show-edit-modal', {
          value: props.value,
          groupId: groupId.value,
        });
      };

      const goToAnswers = (questionId) => {
        router.push({ name: 'ive-answers-listing', params: { questionId } });
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        loading,
        groupId,
        goToAnswers,
        editOption,
        ShowEditWeightModal,
        translate,
        perms,
        tableHeader,
        tableTitle,
        weight,
        tableData,
        can,
        toggleStatusOption,
        showCreateQuestionModal,
        showEditGroupModal,
      };
    },
  });
