
  import { defineComponent, ref, watch } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { hideModal } from '@/core/helpers/dom';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import { Actions } from '@/store/enums/StoreEnums';
  import { QuestionObject } from '@/store/modules/IVE/QuestionsModule';
  import { useStore } from 'vuex';
  import { useAbility } from '@casl/vue';
  import { useI18n } from 'vue-i18n/index';
  export default defineComponent({
    name: 'question-editing',
    props: {
      editData: { type: Number, required: false },
    },
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    emit: ['new-value-created'],
    async setup(props, { emit }) {
      const submitButtonRef = ref<null | HTMLButtonElement>(null);
      const editQuestionValueModalRef = ref<null | HTMLElement>(null);
      const store = useStore();
      const { t, te } = useI18n();
      const { can } = useAbility();
      const question = ref<QuestionObject | null>({
        id: 0,
        titleAr: '',
        titleEn: '',
        sort: 0,
        weight: 0,
        slug: '',
        tempWeight: 0,
        title: '',
        isActive: true,
        groupId: 0,
      });

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      watch(
        () => props.editData,
        async (questionID) => {
          const { data } = await store.dispatch(
            Actions.GET_QUESTION,
            questionID
          );
          question.value = data.data;
        }
      );

      const validationSchema = Yup.object().shape({
        titleEn: Yup.string()
          .required(() => translate('EN_NAME_IS_REQUIRED_FIELD'))
          .label('English Name'),
        titleAr: Yup.string()
          .required(() => translate('AR_NAME_IS_REQUIRED_FIELD'))
          .label('Arabic Name'),
        sort: Yup.number()
          .required(() => translate('SORT_IS_REQUIRED_FIELD'))
          .label('sort'),
      });

      const submit = async (values) => {
        values.groupId = Number(props.editData);
        values.sort = Number(values.sort);
        if (submitButtonRef.value) {
          // eslint-disable-next-line
          submitButtonRef.value!.disabled = true;
          submitButtonRef.value.setAttribute('data-kt-indicator', 'on');
        }

        await store.dispatch(Actions.UPDATE_QUESTION, {
          id: question.value?.id,
          data: values,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButtonRef.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButtonRef.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_GROUP_QUESTION'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            emit('new-value-created');
            //Deactivate indicator
            submitButtonRef.value?.removeAttribute('data-kt-indicator');
            // eslint-disable-next-line
            submitButtonRef.value!.disabled = false;
            hideModal(editQuestionValueModalRef.value);
          });
        }
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        translate,
        validationSchema,
        submit,
        question,
        submitButtonRef,
        editQuestionValueModalRef,
        can,
      };
    },
  });
