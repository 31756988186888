
  import { computed, defineComponent, onMounted, ref } from 'vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useAbility } from '@casl/vue';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n/index';
  import { Actions } from '@/store/enums/StoreEnums';
  import GroupValuesTable from '@/views/manage/IVE/finishing-quality/groups/GroupValuesTable.vue';
  import QualityGroupsCreating from '@/views/manage/IVE/finishing-quality/groups/QualityGroupsCreating.vue';
  import QualityGroupsWeight from '@/views/manage/IVE/finishing-quality/groups/QualityGroupsWeight.vue';
  import QualityGroupsEditing from '@/views/manage/IVE/finishing-quality/groups/QualityGroupsEditing.vue';
  import QuestionsCreating from '@/views/manage/IVE/finishing-quality/questions/QuestionsCreating.vue';
  import QuestionsEditing from '@/views/manage/IVE/finishing-quality/questions/QuestionsEditing.vue';
  import QuestionWeight from '@/views/manage/IVE/finishing-quality/questions/QuestionWeight.vue';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';
  import { useRouter } from 'vue-router';

  interface Weight {
    groupTitle: string;
  }
  export default defineComponent({
    name: 'quality-groups-listing',
    components: {
      GroupValuesTable,
      QualityGroupsCreating,
      QualityGroupsEditing,
      QuestionsCreating,
      QuestionsEditing,
      QualityGroupsWeight,
      QuestionWeight,
    },
    async setup() {
      const { t, te } = useI18n();
      const { can } = useAbility();
      const store = useStore();
      const groupId = ref<number>(0);
      const editData = ref<number>(0);
      const editWeight = ref<Weight | undefined>();
      const router = useRouter();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('qualityGroupsLisitng'), [
          translate('ive'),
        ]);
        reinitializeComponents();
      });

      const refreshValues = async () => {
        await store.dispatch(Actions.GET_QUALITY_GROUPS);
      };
      await refreshValues();

      const groupsValues = computed(() => store.getters.qualityGroupsList);

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const showQuestionCreateModal = (val) => {
        groupId.value = val.groupId;
      };
      const showGroupEditModal = (val) => {
        editData.value = val.groupId;
      };

      const showEditQuestionModal = (val) => {
        editData.value = val;
      };

      const showEditQuestionWeightModal = (val) => {
        editWeight.value = val;
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        groupId,
        editData,
        editWeight,
        groupsValues,
        goTo,
        translate,
        can,
        showQuestionCreateModal,
        showEditQuestionModal,
        showEditQuestionWeightModal,
        showGroupEditModal,
        refreshValues,
      };
    },
  });
